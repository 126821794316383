<template>
<div>
    <!-- <linkbutton toplabel="Update person" @click="UpdatePerson()"/> -->
    <smallbutton @click="UpdatePerson()" :toplabel="updateText" :class="buttonclass"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="myform">
                <label class="titlelabel">{{updateText}}</label>

                <formentry  inputtype="text"  label="First Name" isrequired='True' v-model="person.FirstName" v-if="isVisible('FirstName')"/>
                <formentry  inputtype="text"  label="Last Name" isrequired='True' v-model="person.LastName"  v-if="isVisible('LastName')"/>
                <formentry  inputtype="text"  label="ID"  v-model="person.StudentID"  v-if="isVisible('ID')" :isrequired="isRequired('ID')"/>
                
                <div class="entry"  v-if="isVisible('Location')">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Location</label>
                    </div>
                    <div class="entryTextDiv">
                    <select name="" id="" class='entrySelect' required v-model="person.locationId_Primary" @change="selectLocation()" >
                        <option v-for="loc in locationList" :key="loc.id" :value="loc.id" >
                            {{loc.name}}
                        </option>
                    </select>
                    </div>
                </div>
                <div class="entry"  v-if="isVisible('PersonRole')">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Role</label>
                    </div>
                    <div class="entryTextDiv">
                    <select name="" id="" class='entrySelect' v-model="person.personRoleId" required >
                        <option v-for="role in personRoleList" :key="role.id" :value="role.id" >
                            {{role.name}}
                        </option>
                    </select>
                    </div>
                </div>
                <br>
                <formentry  inputtype="date"  label="Birthdate"  v-model="person.Birthdate"  v-if="isVisible('Birthdate')" :isrequired="isRequired('Birthdate')"/>
                <formentry  inputtype="text"  label="DL"  v-model="person.InternalID"  v-if="isVisible('DL')" :isrequired="isRequired('DL')"/>
                <formentry  inputtype="text"  label="SSN"  v-model="person.fId"  v-if="isVisible('SSN')"/>
                <br>
                <div class="entry"  v-if="isVisible('Course')">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Select Course Type</label>
                    </div>
                    <div class="entryTextDiv">
                    <select name="" id="" class='entrySelect' v-model="person.ProductTypeGlobalKey"  @change="getProducts()" :required="isRequired('Course')">
                        <option v-for="ptype in ProductTypeList" :key="ptype.guid" :value="ptype.guid" >
                            {{ptype.name}}
                        </option>
                    </select>
                    </div>
                </div>
                <div class="entry"  v-if="isVisible('Course')">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Select Course</label>
                    </div>
                    <div class="entryTextDiv">
                    <select name="" id="" class='entrySelect' v-model="person.ProductGlobalKey"  @change="selectProduct()" :required="isRequired('Course')">
                        <option v-for="p in ProductList" :key="p.guid" :value="p.guid" >
                            {{p.name}} ( {{toCurrency(p.price)}})
                        </option>
                    </select>
                    </div>
                </div>
                <div class="entry"  v-if="isVisible('TuitionPaymentType')">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Sponsor</label>
                    </div>
                    <div class="entryTextDiv">
                        <select name="" id="" class='entrySelect' v-model="person.sponsorId"  >
                            <option v-for="sponsor in tuitionPaymentTypeList" :key="sponsor.id" :value="sponsor.id" >
                                {{sponsor.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="entry"  v-if="isVisible('ReferredBy')">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Referred By</label>
                    </div>
                    <div class="entryTextDiv">
                        <select name="" id="" class='entrySelect' v-model="person.referredId"  >
                            <option v-for="referred in referredByList" :key="referred.id" :value="referred.id" >
                                {{referred.Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="entry"  v-if="isVisible('MealPlan')">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Meal Plan</label>
                    </div>
                    <div class="entryTextDiv">
                        <select name="" id="" class='entrySelect' v-model="person.MealPlan"  >
                            <option v-for="mealplan in mealplanList" :key="mealplan.MealPlanID" :value="mealplan.Name" >
                                {{mealplan.Name}}
                            </option>
                        </select>
                    </div>
                </div>

                <br>
                <formentry  inputtype="email"  label="Email Address"  v-model="person.EmailAddress"  v-if="isVisible('Email')"/>
                <formentry  inputtype="tel"  label="Phone Number" @blur="leavePhone"  v-model="person.PhoneNumber1"  v-if="isVisible('Phone')"/>
                

                 <formentry label="Address 1"  v-model="person.AddressLine1"  v-if="isVisible('Address')"/>
                <formentry label="Address 2"  v-model="person.AddressLine2" v-if="isVisible('Address')"/>
                <formentry label="City"  v-model="person.City" v-if="isVisible('Address')" :isrequired="isRequired('Address')"/>
                <div class="entry" v-if="isVisible('Address')">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">State</label>
                    </div>
                    <div class="entryTextDiv">
                    <select name="" id="" class='entrySelect' v-model="person.StateID" :required="isRequired('Address')">
                        <option v-for="state in states" :key="state.id" :value="state.id" >
                            {{state.name}}
                        </option>
                    </select>
                    </div>
                </div>
                <formentry label="Zip"  v-model="person.ZipCode" v-if="isVisible('Address')" :isrequired="isRequired('Address')"/>
                <br>
                <div class="vertFlex ">
                    <label for="" class="mediumtext">How do you want to receive updates?</label>
                    <label for="" class="mediumtext">(choose at least one)</label>
                
                    <div class="horizFlex topMargin centered">
                        <input class="entryCheckbox" type="checkbox" v-model="person.optInForText" >
                        <label for="" :class="checkClass(person.optInForText)">Via text message </label>
                    </div>
                    <label for="" class="mediumtext">(Student has given you consent to send text messages and understands that standard messaging and data rates may apply)</label>
                    <div class="horizFlex centered">
                        <input class="entryCheckbox" type="checkbox" v-model="person.optInForEmail" >
                        <label for="" :class="checkClass(person.optInForEmail)">Via email</label>
                    </div>
                </div>
    
                <smallbutton  @click="saveNewperson()" toplabel="Save" />
                <smallbutton  @click="cancelupdate()" toplabel="Cancel"/>
                <div>
                    <label class="responsemessage">{{message}}</label>
               </div>
          </form>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../services/datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'
import pencilbutton from '../controls/pencilbutton.vue'
export default {
    name:'updateperson',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton,
        pencilbutton
    },
    props:{
        inperson:{
            type:Object,
            required:true
        },
        personTypeText:{
            type:String,
            default:'Employee'
        },
        isAdd:{
            type:Boolean,
            default:false
        },
        buttonclass:{
            type:String,
            default:'updatebutton'
        },

    },
    mixins:[datamethods,format],
    data(){
        return{
            message:'',
            person:{},
            personRoleList:[],
       

            ProductTypeList:[],
            ProductList:[],
            productSale: {
                walletId:-1,
                accountId:-1,
                locationId:-1,
                restrictToProductTypeGlobalKey:'',
                restrictToProductGlobalKey:'',
                price:0,
                walletTokenId:-1,
            },
           productamount:0
        }
    },
    computed:{
        ...mapFields([
           'mealplanList',
           'locationList',
           'tuitionPaymentTypeList',
           'referredByList',
           'states'
        ]),
        updateText(){
            if(this.isAdd){
                return "Add " + this.personTypeText
            }else{
                return "Update " + this.personTypeText
            }
            
        },
        isEmployee(){
            return this.personTypeText == 'Employee'
        },
        isStudent(){
            return this.personTypeText == 'Student'
        },
    },
    methods:{
        isRequired(field){
            switch (field) {
                case 'ID':
                    return window.REQURE_ID
                    break;
                case 'DL':
                    return window.REQUIRE_DL
                    break;
                case 'Birthdate':
                    return window.REQUIRE_BIRTHDATE
                    break;
                case 'Course':
                    return window.REQUIRE_COURSE
                    break;
                case 'Address':
                    return window.REQUIRE_ADDRESS
                    break;
                default:
                    return true
                    break;
            }
        },
        isVisible(field){
            console.log('isvis ' + field)
            switch (field) {
                case 'ID':
                    return window.SHOW_ID
                    break;
                case 'DL':
                    return window.SHOW_DL && this.isStudent
                    break;
                case 'SSN':
                    return window.SHOW_SSN && this.isStudent
                    break;
                case 'Course':
                    return window.SHOW_COURSE && this.isStudent && this.isAdd
                    break;
                case 'TuitionPaymentType':
                    return window.SHOW_TUITIONPAYMENTTYPE && this.isStudent  && this.isAdd
                    break;
                case 'ReferredBy':
                    return window.SHOW_REFERREDBY && this.isStudent  && this.isAdd
                    break;
                case 'MealPlan':
                    return window.SHOW_MEALPLAN
                    break;
                case 'Address':
                    return window.SHOW_ADDRESS 
                    break;
                case 'Due':
                    return window.SHOW_ACCOUNT_DUE
                    break;
                case 'Balance':
                    return window.SHOW_ACCOUNT_BALANCE
                    break;
                case 'Birthdate':
                    return window.SHOW_BIRTHDATE
                    break; 
                default:
                    return true
                    break;
            }
        },
        toCurrency(value){
            return "$" + Number(value).toFixed(2);
        },
       UpdatePerson(){
        if(this.isAdd){
            this.person = {
                ID:0,
                FirstName:null,
                LastName:null,
                StudentID:null,
                locationId_Primary:-1,
                personRoleId:-1,
                Birthdate:null,
                fId:null,
                InternalID:null,
                sponsorId:-1,
                referredId:-1,
                EmailAddress:null,
                MealPlan:null,
                AddressLine1:null,
                AddressLine2:null,
                City:null,
                StateID:-1,
                ZipCode:null,
                optInForText:false,
                optInForEmail:false
            }
        }else{
            this.person = this.inperson
            this.person.Birthdate = this.$options.filters.formatDateForInput(this.person.Birthdate)
        }
        
        this.getRoles()

        this.$refs.mymodal.openModal()
       },
        selectLocation(){
            this.getProductTypes()
        },
        checkClass(selected){
            if(selected){
                return "entryCheckLabelbold"
            }else{
                return "entryCheckLabel"
            }
        },
        cancelupdate(){
            this.$refs.mymodal.closeModal()
            this.$emit('refresh')
            
        },
        async saveNewperson(){
            if(this.$refs.myform.checkValidity()==false){return}
            if(!this.person.optInForText && !this.person.optInForEmail){
                this.message="Please select either text or email updates"
                return
            }
            if(this.person.optInForText && this.person.PhoneNumber1==''){
                this.message="Please enter a phone number for text updates"
                return
            }

            let req = {
                person:this.person,
                amount:this.productamount
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'updateperson')
            if(response.Successful){
                //save prod sale


                this.$refs.mymodal.closeModal()
                console.log(response)

                if(this.isAdd){
                    this.$emit('refreshnew',response.person.ID, response.walletId)
                }else{
                    this.$emit('refresh')
                }
                
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        leavePhone(){
            this.person.PhoneNumber1=this.$options.filters.formatPhone(this.person.PhoneNumber1);
        },
         
        async getRoles(){
            let req = {
                locationId:-1,
                employeeOnly:this.isEmployee,
                studentOnly:this.isStudent
            }
            //this.beforeAPI();
            var response = await this.callAPI(req,'rolelist')

            //handle response
            if(response.Successful){
            this.personRoleList = response.PersonRoleList
    
            if(this.personRoleList.length>0){
               //find student and pick it as default
                var studentId=-1
                this.personRoleList.forEach(myFunction2)

                function myFunction2(item, index, arr) {
                    let acct=arr[index]
                    if(acct.name=='Student'){
                        studentId=acct.id
                    }
                }
                //this.personRoleId=studentId 
            }
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        
        async getProductTypes(){
            let req = {
                walletId:0,
                locationId:this.person.locationId_Primary
            }
            this.$store.dispatch('beforeAPI');;
            var response = await this.callAPI(req,'producttypes')

            //handle response
            if(response.Successful){
            this.ProductTypeList = response.productTypeList
    
            if(this.ProductTypeList.length==1){
                //select the type to skip it
            
                this.getProducts(this.productTypeList[0].guid)
                
            }
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        async getProducts(){
            var guid = this.person.ProductTypeGlobalKey
            console.log('get prod ' + guid)
            this.selectedProductTypeGUID=guid
            let req = {
                walletId:0,
                locationId:this.person.locationId_Primary,
                productTypeGUID:guid
            }
            this.$store.dispatch('beforeAPI');;
            var response = await this.callAPI(req,'products')

            //handle response
            if(response.Successful){
            this.ProductList = response.productList
            
            }else{
            this.message = response.Message
            }
            this.pleaseWait=false;
        },
       selectProduct(){
           var price=0;
           let guid = this.person.ProductGlobalKey
           this.ProductList.forEach(myFunction2)

            function myFunction2(item, index, arr) {
                let acct=arr[index]
                if (acct.guid==guid){
                    price=acct.price
                }
            }
            this.productamount=price
       },
       async saveTrans(){
     
            //send in to start transaction
            let req = this.productSale
            this.beforeAPI();
            var response = await this.callAPI(req,'productsale_admin')

            //handle response
            if(response.Successful){
            
            this.responseMessage="Product Transaction Completed"
            
            }else{
            this.message = response.Message
            }
            this.pleaseWait=false;

        
            
        },
        
    }
}
</script>

<style>
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.updatebutton{
    height:30px;
    width:150px;
    border-radius: 5px;
    padding:3px;
    margin:0px 0px 10px 0px;
}
</style>





























